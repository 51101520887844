define("discourse/plugins/discourse-adplugin/discourse/templates/admin/plugins-house-ads", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="adplugin-mgmt">
    <h1>{{i18n "admin.adplugin.house_ads.title"}}</h1>
    {{#if this.model.length}}
      <div class="content-list">
        <div class="house-ads-actions">
          <LinkTo
            @route="adminPlugins.houseAds.show"
            @model="new"
            class="btn btn-primary"
          >
            {{d-icon "plus"}}
            <span>{{i18n "admin.adplugin.house_ads.new"}}</span>
          </LinkTo>
          <LinkTo @route="adminPlugins.houseAds.index" class="btn btn-default">
            {{d-icon "cog"}}
            <span>{{i18n "admin.adplugin.house_ads.settings"}}</span>
          </LinkTo>
        </div>
        <ul class="house-ads-list">
          {{#each this.model as |ad|}}
            <li class="house-ads-list-item">
              {{#link-to route="adminPlugins.houseAds.show" model=ad.id}}
                {{ad.name}}
              {{/link-to}}
            </li>
          {{/each}}
        </ul>
      </div>
    {{/if}}
    {{outlet}}
  </div>
  */
  {
    "id": "/2UDykfk",
    "block": "[[[10,0],[14,0,\"adplugin-mgmt\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[28,[35,2],[\"admin.adplugin.house_ads.title\"],null]],[13],[1,\"\\n\"],[41,[30,0,[\"model\",\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"content-list\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"house-ads-actions\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@model\"],[\"adminPlugins.houseAds.show\",\"new\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,5],[\"plus\"],null]],[1,\"\\n          \"],[10,1],[12],[1,[28,[35,2],[\"admin.adplugin.house_ads.new\"],null]],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[39,4],[[24,0,\"btn btn-default\"]],[[\"@route\"],[\"adminPlugins.houseAds.index\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,5],[\"cog\"],null]],[1,\"\\n          \"],[10,1],[12],[1,[28,[35,2],[\"admin.adplugin.house_ads.settings\"],null]],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"house-ads-list\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[14,0,\"house-ads-list-item\"],[12],[1,\"\\n\"],[6,[39,4],null,[[\"route\",\"model\"],[\"adminPlugins.houseAds.show\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"              \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"]],[]]]]],[1,\"          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[46,[28,[37,12],null,null],null,null,null],[1,\"\\n\"],[13]],[\"ad\"],false,[\"div\",\"h1\",\"i18n\",\"if\",\"link-to\",\"d-icon\",\"span\",\"ul\",\"each\",\"-track-array\",\"li\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/templates/admin/plugins-house-ads.hbs",
    "isStrictMode": false
  });
});